<template>
  <router-link :to="{ name: 'catalogue' }">
    <div class="logo--title"></div>
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
}
</script>

<style scoped lang='scss'>

.logo--title {
  width: 188px;
  height: 24.5px;
  background-image: url("../../assets/mainLogo.svg");
  background-repeat: no-repeat;
  background-size: cover;

  &:hover {
    background-image: url("../../assets/hoverLogo.svg")
  }
}

</style>