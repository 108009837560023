<template>
  <div class="cart">
    <transition name="bounce">
      <router-link class="cart--self" :to="{ name: 'cart'}"  v-if="getCartLength">
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 32.166 32.166">
          <path d="M0,0H32.166V32.166H0Z" fill="none"/>
          <path d="M9.042,23.444a2.681,2.681,0,1,0,2.681,2.681A2.677,2.677,0,0,0,9.042,23.444ZM1,3.34a1.344,1.344,0,0,0,1.34,1.34h1.34L8.505,14.853,6.7,18.123A2.685,2.685,0,0,0,9.042,22.1H23.784a1.34,1.34,0,0,0,0-2.681H9.042l1.474-2.681H20.5a2.668,2.668,0,0,0,2.345-1.38l4.8-8.7a1.335,1.335,0,0,0-1.166-1.984H6.642l-.9-1.917A1.33,1.33,0,0,0,4.538,2H2.34A1.344,1.344,0,0,0,1,3.34Zm21.444,20.1a2.681,2.681,0,1,0,2.681,2.681A2.677,2.677,0,0,0,22.444,23.444Z" transform="translate(0.34 0.681)" class="icon"/>
        </svg>
        <div class="quantity">{{ getCartLength }}</div>
      </router-link>
    </transition >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'Contacts',
  computed: {...mapGetters(['getCartLength'])},
}
</script>

<style scoped lang='scss'>

.cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  // @media (max-width: 640px) {
  //   display: none;
  // }

  &--self {
    position: relative;

    &:hover .icon { 
      fill: #B5B5B5;
    }

    &:hover .quantity {
      background-color: #fff;
      color: var(--main-red);
    }
  }

  .quantity {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -5px;
    right: -5px;
    background-color: var(--main-red);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat';
  }
  
  .icon {
    fill: #fff;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

</style>