<template>
  <nav class="sidebar" @click="toggleHamburger">
    <ul class="sidebar--links">
      <transition name="slide-from-left" appear>
        <LinkButton
          class="sidebar--link"
          :link="{ name: 'cart', text: `Корзина (${getCartLength}) `}"
        />
      </transition>
      <transition name="slide-from-left-1" appear>
        <LinkButton
          class="sidebar--link sidebar--link-hidden"
          :link="{ name: 'reviews', text: 'Отзывы'}"
        />
      </transition>
      <transition name="slide-from-left-2" appear>
        <LinkButton
          class="sidebar--link sidebar--link-hidden"
          :link="{ name: 'faq', text: 'FAQ'}"
        />
      </transition>
      <transition name="slide-from-left-3" appear>
        <LinkButton
          class="sidebar--link"
          :link="{ name: 'contacts', text: 'Контакты'}"
        />
      </transition>
    </ul>
  </nav>
</template>

<script>
import LinkButton from '@/components/ui/buttons/LinkButton'
import { mapGetters, mapActions } from 'vuex'


export default {
  name: 'Sidebar',
  components: {
    LinkButton
  },
  computed: mapGetters(['getCartLength']),
  methods: mapActions(['toggleHamburger'])
}
</script>

<style scoped lang='scss'>

.sidebar {
  z-index: 3;
  position: fixed;
  height: 100%;
  background-color: #000;
  // background-color: transparent;
  width: 30%;
  max-width: 370px;
  // top: var(--header-height);
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 640px) {
    width: 100%;
    background-color: #000;
  }


  &--links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--link {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 30px 0;

    &-hidden {
      display: none;

        @media screen and (max-width: 640px) {
          display: inherit;
        }
    }

    &:hover {
      opacity: 0.7;
    }
  }


}

@keyframes slide-from-left {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-from-left-enter-active {
  opacity: 0;
  animation: slide-from-left .8s .6s;
}

.slide-from-left-3-enter-active {
  opacity: 0;
  animation: slide-from-left .8s .8s;
}

@media (max-width: 640px) {
  .slide-from-left-enter-active {
    opacity: 0;
    animation: slide-from-left .5s .3s;
  }

  .slide-from-left-1-enter-active {
    opacity: 0;
    animation: slide-from-left .5s .4s;
  }

  .slide-from-left-2-enter-active {
    opacity: 0;
    animation: slide-from-left .5s .5s;
  }

  .slide-from-left-3-enter-active {
    opacity: 0;
    animation: slide-from-left .5s .6s;
  }
}

</style>