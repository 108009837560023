<template>
  <li class="item" :class="link.class">
    <router-link :to="{ name: link.name }" >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27" v-if="arrow">
        <g id="west-24px" transform="translate(-0.234 0.106)">
          <rect id="Rectangle_988" data-name="Rectangle 988" width="27" height="27" transform="translate(0.234 -0.106)" fill="none"/>
          <path id="Path_1285" data-name="Path 1285" d="M9.968,20.937l1.605-1.605L6.36,14.107H24.767V11.83H6.36l5.225-5.225L9.968,5,2,12.968Z" transform="translate(0.319 0.672)" fill="#fff"/>
        </g>
      </svg>
      {{ link.text }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'NavigationButton',
  props: ['link', 'arrow']
}
</script>

<style scoped>
</style>