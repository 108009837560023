<template>
  <div>
    <div class="upper-notification" v-if="!notification.isUnderstood">
      <div class="upper-notification__content">
        <span v-html="notification.text" class="upper-notification__text">
        </span>
        <button class="upper-notification__btn" @click="changeNotification">
          Понятно
        </button>
      </div>
    </div>
    <div 
      class="shutter" 
      :class="{'shutter-active': showHamburger}"
      @click="hideHamburger"
    ></div>
    <TheHamburger />
    <TheHeader class="header" :class="{ 'header-notification': notification.isEnabled }" />
    <transition name="rolling">
      <TheSidebar v-if="showHamburger" />
    </transition>
    <div class="wrapper">
      <main class="main">
        <router-view></router-view>
      </main>
    </div>
    <OnTopButton />
    <footer class="footer">
      <div class="media">
        <TheMedia />
      </div>
      <TheFooter />
    </footer>
  </div>
</template>

<script>
import TheHeader from '@/components/layout/TheHeader'
import TheFooter from '@/components/layout/TheFooter'
import TheMedia from '@/components/layout/TheMedia'
import TheSidebar from '@/components/layout/TheSidebar'
import TheHamburger from '@/components/layout/TheHamburger'

import OnTopButton from '@/components/ui/buttons/OnTopButton'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'MainPage',
  components: {
    TheHeader,
    TheFooter,
    TheMedia,
    TheSidebar,
    OnTopButton,
    TheHamburger,
  },
  computed: mapState(['showHamburger', 'notification']),
  methods: {
    ...mapActions(['toggleHamburger', 'fetchNotification', 'setNotification']),
    hideHamburger() {
      this.toggleHamburger();
    },
    changeNotification() {
      this.setNotification({ isUnderstood: true });
      localStorage.setItem('upperNotificationIsUnderstood', 'true');
    }
  },
  async created() {
    await this.fetchNotification();
  }
}
</script>

<style scoped lang='scss'>

.upper-notification {
  background-color: #bcbcbc;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 5px 0;
  z-index: 2;
  min-height: var(--header-height);

  &__content {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
  }

  ::v-deep p {
    color: #000;
  }

  &__text {
    margin-right: 10px;
  }

  &__btn {
    padding: 5px 10px;
    border-radius: 8px;
    background-color: var(--main-red);

    &:hover {
      cursor: pointer;
    }
  }
}

.shutter {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: -1;

  &-active {
    z-index: 3;
    opacity: .25;
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  background-color: #1c1c1c;
  align-items: center;
  height: var(--header-height);
  padding: 0 30px;

  @media screen and (max-width: 640px) {
    backdrop-filter: blur(6px);
    background-color: transparent;
  }

  
  @media screen and (max-width: 350px) {
    padding: 0 15px;
    justify-content: space-around;
  }
}

.main {
  grid-area: main;
  margin-bottom: 50px;
}

.footer {
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  min-height: 220px;

  @media screen and (max-width: 640px) {
    min-height: 330px;
  }
}

.wrapper {
  display: grid;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-areas:
                      '. . .'
                      '. main .';
  grid-template-columns: 3% auto 3%;
  grid-template-rows: var(--header-height) auto;
  row-gap: 30px;
  min-height: 90vh;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1% auto 1%;
  }
}

.rolling-enter-active,
.rolling-leave-active {
  transition: transform 1s ease;

  @media (max-width: 640px) {
    transition: transform 0.3s ease;
  }

}

.rolling-enter,
.rolling-leave-to {
  transform: translateX(100%);
}

.header-notification {
  top: var(--header-height);
}
</style>
