<template>
  <section class="media">
    <transition appear name="media-slide-1">
      <Instagram 
        color="#d4d4d4" 
        href="https://www.instagram.com/the_mantra_shop/"
      />
    </transition>

    <transition appear name="media-slide-2">
      <VK color="#d4d4d4" href="https://vk.com/themantrashop"/>
    </transition>

    <transition appear name="media-slide-3">
      <Youtube color="#d4d4d4" href="https://www.youtube.com/channel/UChTQQ3y5Gb0DCedPc_6hm1w" />
    </transition>

    <transition appear name="media-slide-4">
      <Telegram color="#d4d4d4" href="https://telegram.im/@The_Mantra_shop" />
    </transition>
  </section>
</template>

<script>
import Instagram from '@/components/common/MediaIcons/Instagram'
import VK from '@/components/common/MediaIcons/VK'
import Youtube from './Youtube'
import Telegram from './Telegram'


export default {
  name: 'Media',
  components: {
    Telegram,
    Instagram,
    Youtube,
    VK,
  }
}
</script>

<style scoped lang="scss">

.media {
  position: fixed;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  top: 40%;
  left: 20px;
  height: 160px;
  color: white;
  display: flex;



  @media screen and (min-width: 1500px) {
    left: 45px;
  }

  @media screen and (max-width: 640px) {
    position: inherit;
  }
}



  @keyframes media-animation {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .media-slide-1-enter-active {
    animation: media-animation 2.5s;
    backface-visibility: hidden;
  }

  .media-slide-2-enter-active {
    animation: media-animation 2s;
    backface-visibility: hidden;
  }
  .media-slide-3-enter-active {
    animation: media-animation 1.5s;
    backface-visibility: hidden;
  }
  .media-slide-4-enter-active {
    animation: media-animation 1s;
    backface-visibility: hidden;
  }


</style>