<template>
  <transition name="fade">
    <button class="on-top" v-if="show" @click="scrollTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="53.25" viewBox="0 0 61 53.25">
        <g id="get_app-24px_1_" data-name="get_app-24px (1)" transform="translate(61 53.25) rotate(180)">
          <path id="Path_1335" data-name="Path 1335" d="M0,0H61V53.25H0Z" fill="none"/>
          <path id="Path_1336" data-name="Path 1336" d="M40.583,20.558H30.417V3H15.167V20.558H5L22.792,41.042Z" transform="translate(7.708 4.625)"/>
        </g>
      </svg>
    </button>
  </transition>
</template>

<script>
export default {
  name: 'OnTopButton',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
    }
  },
  created() {
    document.addEventListener('scroll', () => {
      if (document.documentElement.clientHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight * 0.5) {
        this.show = true;
      } else {
        this.show = false;
      }
    })
  }
}
</script>

<style scoped lang="scss">
  .on-top {
    background-color: transparent;
    display: none;
    padding: 19px 22px;
    backdrop-filter: blur(6px);
    border: 2px solid #707070;
    border-radius: 9px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    fill: #ccc;

    &:hover {
      cursor: pointer;
      fill: #000;
    }

    @media screen and (max-width: 640px) {
      display: inherit;
    }
  }
</style>