<template>
  <button class="menu_btn" 
    @click="toggleHamburger" 
    :class="{ open: showHamburger, 'has-notification': notification.isEnabled }"
    data-hamburger="true"
  >
    <div class="menu_btn--burger" ></div>
  </button>
</template>

<script>
import { mapState, mapActions } from 'vuex'


export default {
  name: 'Hamburger2',
  computed: mapState(['showHamburger', 'notification']),
  methods: mapActions(['toggleHamburger']),
}
</script>

<style scoped lang='scss'>

.menu_btn {
  position: fixed;
  z-index: 25;
  right: 24px;
  top: 12px;
  // width: 45px;
  // border: 1px solid #fff;
  height: 35px;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: 400px) {
    right: 15px;
  }

  &--burger {
    width: 35px;
    height: 2px;
    background-color: var(--main-red);
    border-radius: 5px;
    transition: all .6s ease-in-out;
    display: flex;

    &::before,
    &::after {
      content: '';
      transition: all .6s ease-in-out;
      position: absolute;
      width: 35px;
      height: 2px;
      background-color: var(--main-red);
      border-radius: 10px;
    }

    &::before {
      transform: translateY(-10px);
    }

    &::after {
      transform: translateY(10px);
    }
  }

  &.open .menu_btn--burger {
    transform: translateX(50px);
    background: transparent;

    &::before {
      transform: rotate(45deg) translate(-35px, 35px);
    }

    &::after {
      transform: rotate(-45deg) translate(-35px, -35px);
    }
    
  }
  
  &:hover {
    opacity: 0.7;
  }
}

@media (max-width: 640px) {

  .menu_btn {

    &--burger {
      transition: transform 0.3s ease;

      &::before,
      &::after {
        transition: all .3s ease-in-out;
      }
    }
  }
}

.has-notification {
  top: 68px;
}
</style>
