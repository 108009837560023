<template>
    <transition appear name="header-slide-4">
  <nav class="nav">

    <ul class="nav--list">
        
        <transition appear name="header-slide-1">
          <li class="nav--item" :class="{ active: 'catalogue' === currentPage}">
            <router-link class="link" :to="{ name: 'catalogue' }">
              Каталог
            </router-link>
          </li>
        </transition>
        
        <transition appear name="header-slide-2">
          <li class="nav--item" :class="{ active: 'reviews' === currentPage}">
            <router-link class="link" :to="{ name: 'reviews' }">
              Отзывы
            </router-link>
          </li>
        </transition>

        <transition appear name="header-slide-3">
          <li class="nav--item" :class="{ active: 'faq' === currentPage}">
            <router-link class="link" :to="{ name: 'faq' }">
              FAQ
            </router-link>
          </li>
        </transition>

    </ul>
  </nav>
  </transition>
</template>

<script>


export default {
  name: 'Navigation',
  computed: {
    currentPage() {
      return this.$route.name;
    }
  },
}
</script>

<style lang='scss' scoped>

  .nav {

    &--list {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      letter-spacing: 0.02rem;
      height: 100%;
      margin: 0;
      padding: 0;
    }
    

    &--item {
      height: 100%;
      padding: 5px 20px;
      border-radius: 34px;
      
      &.active {
        border: 2px solid var(--main-red);
        margin: -2px;
      }
      
      &:hover {
        cursor: pointer;
      }
      
      &:hover .link {
        color: var(--main-red);
      }
    }

    @media screen and (max-width: 640px) {
      display: none;
    }

  }


  .header-slide-1-enter-active {
    animation: logo-animation 1.5s;
    backface-visibility: hidden;
  }

  .header-slide-2-enter-active {
    animation: logo-animation 2s;
    backface-visibility: hidden;
  }

  .header-slide-3-enter-active {
    animation: logo-animation 2.5s;
    backface-visibility: hidden;
  }

  .header-slide-4-enter-active {
    animation: logo-animation 2.5s;
    backface-visibility: hidden;
  }

</style>