<template>
  <a :href="href">
    <svg id="iconfinder_1_Youtube_colored_svg_5296521" xmlns="http://www.w3.org/2000/svg" width="25.004" height="17.503" viewBox="0 0 25.004 17.503" 
    :fill="color" class="media-icon">
      <path id="Path_1269" data-name="Path 1269" d="M24.481,79.534a3.131,3.131,0,0,0-2.211-2.211C20.321,76.8,12.5,76.8,12.5,76.8s-7.819,0-9.769.523A3.131,3.131,0,0,0,.522,79.534a34.93,34.93,0,0,0,0,12.036,3.132,3.132,0,0,0,2.211,2.211c1.95.522,9.769.522,9.769.522s7.819,0,9.769-.522a3.131,3.131,0,0,0,2.211-2.211A32.529,32.529,0,0,0,25,85.552,32.532,32.532,0,0,0,24.481,79.534Z" transform="translate(0 -76.801)"/>
      <path id="Path_1270" data-name="Path 1270" d="M204.8,186.7l6.5-3.751-6.5-3.751Z" transform="translate(-194.795 -174.2)" fill="#1c1c1c"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'Youtube',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>