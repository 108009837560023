<template>
  <div class="footerWrapper">

    <section class="logo">
      <MainLogo />
    </section>

    <section class="info">

      <div class="info--phone">
        Горячая линия <br/>
        <a href="tel: 8-800-201-66-39" class="number medium">8-800-201-66-39</a>
      </div>
      
      <div class="documents">
        <div>
          <span class="documents--ogrn semi-bold">ОГРН:</span>
          <span class="light"> 319619600087007</span>
        </div>

        <div class="documents--id">
          <span class="semi-bold">ИНН:</span>
          <span class="light"> 610109143881</span>
        </div>

        <div class="documents--oferta">
          <router-link :to="{ name : 'offer' }" class="medium">
            Договор публичной оферты
          </router-link>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import MainLogo from '@/components/layout/MainLogo'


export default {
  name: 'Footer',
  components: {
    MainLogo
  }
}
</script>

<style scoped lang='scss'>

.footerWrapper {
  display: flex;
  justify-content: center;
  height: 72%;
}

.logo {
  margin-right: 20px;
  padding-right: 30px;
  border-right: 1px solid #707070;
  height: 100%;
  display: flex;
  align-items: center;
}

.info {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  border-bottom: 1px solid #707070;
  padding-top: 5px;

  &--phone {
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #707070;

    & .number:hover {
      margin-bottom: -2px;
      border-bottom: 2px solid #fff;
    }
  }

  .documents {

    &--oferta a {
      color: #EE2B26;

      &:hover {
        color: #fff;
      }
    }

    &--id {
      margin-bottom: 8px;
    }
  }

}


@media screen and (max-width: 640px) {
  .footerWrapper {
    margin-left: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 80%;
  }
  
  .logo {
    border: none;
    padding: 0;
    margin: 0;
    height: 30%;
  }

  .info {
    padding: 0;

    &--phone {
      margin-bottom: 22px;
      padding-bottom: 10px;
      text-align: center;
    }
  }

  .documents {
    text-align: center;
    padding-bottom: 8px;

  }
}

@media screen and (max-width: 520px) {
  .footerWrapper {
    margin-left: 20px;
  }
  
}
</style>