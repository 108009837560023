<template>
  <a :href="href">
    <svg xmlns="http://www.w3.org/2000/svg" width="24.558" height="21.213" viewBox="0 0 24.558 21.213" class="media-icon" :fill="color">
      <g transform="translate(-91.442 -138.426)">
        <path d="M116,139.18l-3.881,19.576a1.437,1.437,0,0,1-2.038.7l-8.96-6.871-3.261-1.571-5.487-1.843s-.841-.3-.926-.951.951-1,.951-1l21.81-8.552C114.208,138.662,116,137.872,116,139.18Z" transform="translate(0 0)"/>
        <path d="M169.565,201.886s-.263-.025-.586-1.053S167,194.369,167,194.369L180.173,186s.764-.459.73,0c0,0,.136.085-.272.459s-10.344,9.317-10.344,9.317" transform="translate(-69.141 -43.351)" fill="#4a4a4a"/>
        <path d="M201.419,307.192l-3.542,3.236a.61.61,0,0,1-.578.076l.679-6" transform="translate(-96.868 -151.969)"/>
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'Telegram',
  props: ['href', 'color']

}
</script>

<style scoped lang="scss">
</style>