<template>
  <header>
    <transition appear name="header-slide">
      <MainLogo />
    </transition>

    <Navigation />
    
    <section class="options">
      <transition name="slide-fade">
        <CartIcon v-if="!showHamburger" />
      </transition>
      <!-- <Hamburger /> -->
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import MainLogo from '@/components/layout/MainLogo'
import Navigation from './Navigation'
import CartIcon from './CartIcon'
// import Hamburger from './Hamburger'


export default {
  name: 'Header',
  components: {
    MainLogo,
    Navigation,
    CartIcon,
    // Hamburger
  },
  computed: mapState(['showHamburger']),

}
</script>

<style lang='scss'>

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;

    @media screen and (max-width: 400px) {
      width: 75px;
    }
  }

  @keyframes logo-animation {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .header-slide-enter-active {
    animation: logo-animation 1s;
    backface-visibility: hidden;
  }

  .slide-fade-enter-active {
    transition: all 0.9s;
    transition-delay: 50ms;

      @media screen and (max-width: 640px) {
        transition: all 0.6s;
        transition-delay: 0.2s;
      }
  }

  .slide-fade-leave-active {
    transition: all 1s;
    transition-delay: 50ms;
    
    @media screen and (max-width: 640px) {
      transition: all 0.6s;
      transition-delay: 50ms;
    }
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-28vw);
    opacity: 0;
  }
</style>